<template>
  <div class="products-nav">
    <ul class="view-mode-wrap">
      <li class="view-grid sel">
        <a></a>
      </li>

      <li class="view-list">
        <a></a>
      </li>
    </ul>

    <nav class="woocommerce-pagination">
      <ul class="page-numbers">
        <li>
          <a
            @click="handlePageChange(page - 1)"
            v-if="page < pageCount"
            class="prev page-numbers"
            >?</a
          >
        </li>
        <li class="page-numbers">
          <span
            :class="{ 'page-numbers': true, current: pageNumber === page }"
            v-for="pageNumber in getPageNumbers()"
            @click="handlePageChange(pageNumber)"
            :key="pageNumber"
          >
            {{ pageNumber }}
          </span>
        </li>
        <li>
          <a
            @click="handlePageChange(page + 1)"
            v-if="page < pageCount"
            class="next page-numbers"
            >?</a
          >
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Paging",
  data() {
    return {
      posts: [""],
      page: 1,
      perPage: 50,
      pages: [],
    };
  },

  props: {
    products: {
      type: Object,
      required: true,
    },
  },

  mounted() {},
  methods: {
    handlePageChange(page) {
      this.page = page;
      this.$emit("emitPageChange", { pageIndex: page });
    },
    getPageNumbers() {
      let start = this.page > 5 ? this.page - 5 : 0;
      let end = start + 10;
      return Array.from({ length: this.pageCount }, (_, i) => i + 1).slice(
        start,
        end
      );
    },
  },
  computed: {
    pageCount() {
      let l = this.products.totalCount,
        s = this.perPage;

      return Math.floor(l / s);
    },
  },

  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },
};
</script>
