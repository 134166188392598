import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import router from './router';
import store from './store/store';
import ToastPlugin from 'vue-toast-notification';

import VueSweetalert2 from 'vue-sweetalert2';
Vue.filter('formatCurrency', function (value) {
  return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(value);
});
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);
Vue.use(VueRouter)
Vue.config.productionTip = false
Vue.use(ToastPlugin);

new Vue({
  router,
  render: h => h(App),
  store
}).$mount('#app')
