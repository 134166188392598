<template>
  <LayoutCommon
    :categoriesMenu="categoriesMenu"
    @get-product-by-category="getProducts"
  >
    <template v-slot:content-detail>
      <ListProduct
        v-if="products?.items?.length > 0"
        :products="products"
        @emitPageChange="getProducts"
        :hidden-slider="true"
      >
      </ListProduct>
    </template>
    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :is-full-page="true"
      color="#02A8F3"
    ></loading>
  </LayoutCommon>
</template>

<script>
import LayoutCommon from "./Layout.vue";
import apiService from "@/apiService.js";
import endPoint from "@/endPoint.js";
import { getWithParams } from "@/apiService.js";
import ListProduct from "./ListProduct.vue";
export default {
  name: "ProductListCategory",
  data() {
    return {
      categoriesMenu: [],
      isLoading: false,
      products: {},
    };
  },
  components: {
    LayoutCommon,
    ListProduct,
  },

  created() {
    this.getCategories();
    this.getProducts();
  },

  mounted() {},
  methods: {
    async getCategories() {
      const response = await apiService.get(endPoint.GET_CATEGORIES);
      this.categoriesMenu = response.data;
    },

    async getProducts({
      searchTerm = "",
      pageIndex = 1,
      pageSize = 20,
      categoryId = null,
    } = {}) {
      const cateId = this.$route.params.id;
      this.isLoading = true;
      const response = await getWithParams("/api/client/SearchProductAsync", {
        searchTerm,
        pageIndex,
        pageSize,
        categoryId: categoryId || cateId,
      });
      this.products = response.data;
      this.isLoading = false;
    },
  },
};
</script>

