<template>
  <div id="app">
    <!-- <LoadingJquery /> -->
    <!-- <HomePage msg="Welcome to Your Vue.js App" /> -->
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
// import HomePage from "./components/HomePage.vue";
import { loadScript } from "vue-plugin-load-script";
// import LoadingJquery from "./components/Global/LoadingJquery.vue";

// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import "vue-toast-notification/dist/theme-bootstrap.css";

export default {
  name: "App",
  components: {
    // LoadingJquery,
  },
  async created() {
    try {
      await loadScript("/js/jquery-3.1.1.min.js");
      await loadScript("/js/jquery-migrate-1.4.1.min.js");
      await loadScript("/js/modernizr-2.6.2.min.js");
      await loadScript("/js/bootstrap/bootstrap.min.js");
      await loadScript("/js/js.cookie.min.js");
      await loadScript("/js/owl-carousel/owl.carousel.min.js");
      await loadScript("/js/sw_woocommerce/jquery.countdown.min.js");
      await loadScript("/js/colorbox/jquery.colorbox-min.js");
      await loadScript("/js/sw_woocommerce/category-ajax.min.js");
      await loadScript("/js/sw_woocommerce/slick.min.js");
      await loadScript("/js/sw_core/jquery.fancybox.pack.js");
      await loadScript("/js/main.js");
      await loadScript("/js/megamenu.min.js");
      // await loadScript("/js/pathLoader.min.js");
      console.log("All scripts loaded");
    } catch (e) {
      console.log("One or more scripts failed to load", e);
    }
  },

  computed: {
    isProductDetailPage() {
      return this.$route.name === "ProductDetail";
    },
  },
  mounted() {},
  watch: {
    $route(to, from) {
      if (to.name === "ProductDetail" && from.name === "ProductDetail") {
        return;
      }
      // window.location.reload();
    },
  },
};
</script>
<style lang="scss">
@import "@/scss/theme.scss";
</style>

<style lang="css">
@import "/src/css/theme/bootstrap.min.css";
@import "/src/css/theme/font-awesome.min.css";
@import "/src/css/owl-carousel/owl.carousel.min.css";
@import "/src/css/sw_woocommerce/slider.css";
@import "/src/css/colorbox/colorbox.css";
@import "/src/css/sw_core/jquery.fancybox.css";
@import "/src/css/js_composer/js_composer.min.css";

@import "/src/css/theme/app-responsive.min.css";
@import "/src/css/theme/flexslider.min.css";
@import "/src/css/theme/custom-blue.min.css";
@import "/src/css/theme/home-style-1.min.css";
@import "/src/css/woocommerce/woocommerce.css";
</style>
