// create vue x store
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        products: null,
        cart: JSON.parse(localStorage.getItem('cartItem')) || [],
        toast: {
            text: "",
            show: false
        },
        searchTerm: "",

    },
    getters: {
        cartSize: (state) => {
            return state.cart.length;
        },
        cartTotalAmount: (state) => {
            return state.cart.reduce((total, product) => {
                return total + (product.price * product.quantity);
            }, 0);
        },
        toast: (state) => {
            return state.toast;
        },
        cartAdded: (state) => {
            return state.cart;
        },
        getSearchTerm: (state) => {
            return state.searchTerm;
        },
    },
    mutations: {
        setSearchTerm: (state, searchTerm) => {
            state.searchTerm = searchTerm;
        },
        setUpProducts: (state, productsPayload) => {
            //sets the state's  products property to the products array recieved as payload
            state.products = productsPayload;
        },
        addToCart: (state, product) => {
            //find the product in the products list
            // let product = state.products.find((product) => product.id === productId);
            //find the product in the cart list
            let cartProduct = state.cart.find((c) => c.id == product.id);
            if (cartProduct) {
                //product already present in the cart. so increase the quantity
                if (!product.quantity) {
                    cartProduct.quantity++;
                } else { 
                    cartProduct.quantity = product.quantity
                }
               
            } else {
                state.cart.push({
                    // product newly added to cart
                    ...product,
                    stock: product.quantity,
                    quantity: 1,
                });
            }
            //reduce the quantity in products list by 1
            

            UpdateCartInforFromStored(state);
        },
        removeFromCart: (state, product) => {
            //find the product in the products list
            let cartProduct = state.cart.find((c) => c.id === product.id);

            cartProduct.quantity--;
    

            UpdateCartInforFromStored(state);
        },
        deleteFromCart: (state, product) => {
            //find the product index in the cart list
            let cartProductIndex = state.cart.findIndex((c) => c.id === product.id);
            //srt back the quantity of the product to intial quantity
            // remove the product from the cart
            state.cart.splice(cartProductIndex, 1);
            localStorage.setItem('cartItem', JSON.stringify( state.cart) );
        },
        showToast: (state, toastText) => {

            state.toast.show = true;
            state.toast.text = toastText;
        },
        hideToast: (state) => {
            state.toast.show = false;
            state.toast.text = "";
        },

        clearCart: (state) => {
            state.cart = [];
            localStorage.setItem('cartItem', JSON.stringify( state.cart) );
        },

        setQuantity: (state, { productId, quantity }) => {
            let cartProduct = state.cart.find((c) => c.id === productId);
            cartProduct.quantity = quantity;
            UpdateCartInforFromStored(state);
        }
    },
    actions: {
        
        setSearchTerm: ({ commit }, searchTerm) => {
            commit("setSearchTerm", searchTerm);
        },
        addToCart: ({ commit }, productId) => {
            commit("addToCart", productId);
            commit("showToast", "added to cart");
        },
        removeFromCart: ({ commit }, productId) => {
            commit("removeFromCart", productId);
            commit("showToast", "removed from cart");

        },
        deleteFromCart: ({ commit }, productId) => {
            commit("deleteFromCart", productId);
            commit("showToast", "deleted from cart");

        },

        clearCart: ({ commit }) => {
            commit("clearCart");

        }
    }
  });


function UpdateCartInforFromStored (state) {
    let array1 = JSON.parse(localStorage.getItem('cartItem')) || [];
    let array2 = state.cart;
    let mergedArray = array1.map(item => {
      let itemInArray2 = array2.find(i => i.id === item.id);
      return itemInArray2 ? { ...item, ...itemInArray2 } : item;
    });
    
    mergedArray = [...mergedArray, ...array2.filter(i => !array1.find(item => item.id === i.id))];


   
    state.cart = mergedArray;
    // save state.cart to local storage
    localStorage.setItem('cartItem', JSON.stringify( mergedArray) );
    
}