<template>
  <LayoutCommon>
    <template v-slot:content-detail>
      <div class="container">
        <div class="row">
          <div
            id="contents"
            role="main"
            class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
          >
            <div class="page type-page status-publish hentry">
              <div class="entry-content">
                <div class="vc_row wpb_row vc_row-fluid">
                  <div class="wpb_column vc_column_container vc_col-sm-12">
                    <div class="vc_column-inner">
                      <div class="wpb_wrapper">
                        <div class="wpb_gmaps_widget wpb_content_element">
                          <div class="wpb_wrapper">
                            <div class="wpb_map_wraper">
                              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.7846001929524!2d106.6251968745746!3d10.751077459661747!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752c2a94ea2ff3%3A0xa15b82b53e8c3441!2zMSDEkMaw4budbmcgc-G7kSA5LCBQaMaw4budbmcgMTMsIFF14bqtbiA2LCBUaMOgbmggcGjhu5EgSOG7kyBDaMOtIE1pbmggNzAwMDAwLCBWaWV0bmFt!5e0!3m2!1sen!2s!4v1714390114752!5m2!1sen!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="vc_row wpb_row vc_row-fluid contact-bonus contact-bonus1 margin-top-20"
                >
                  <div class="wpb_column vc_column_container vc_col-sm-4">
                    <div class="vc_column-inner">
                      <div class="wpb_wrapper">
                        <div class="wpb_text_column wpb_content_element">
                          <div class="wpb_wrapper">
                            <div class="page-title">
                              <h3>Thông Tin Công Ty</h3>
                            </div>
                            Cửa hàng 1: Số 2, đường số 9. Phường 13, Quận 6, Tp
                            Hồ Chí Minh.
                            <p>
                              Tên cty: Cty TNHH Thiết bị Điện - Điện lạnh Hưng
                              <br />

                              <br />
                              Cửa hàng 2: 628, QL91, P. Trung Kiên, Q. Thốt Nốt,
                              TP Cần Thơ
                            </p>
                          </div>
                        </div>

                        <div class="wpb_text_column wpb_content_element">
                          <div class="wpb_wrapper">
                            <p class="ct ct-street">
                              Đường số 9. Phường 13, Quận 6
                              <br />
                              Tp Hồ Chí Minh.
                            </p>

                            <p class="ct ct-email">
                              Email : ctytnhhthietbidien.dienlanhhung@gmail.com
                            </p>

                            <p class="ct ct-hotline">
                              Hotline : 0939596192(Thành) - 0907248992 (Thu)-
                              0939189062 (Vân )
                            </p>
                          </div>
                        </div>

                        <div class="wpb_text_column wpb_content_element">
                          <div class="wpb_wrapper">
                            <div id="socials" class="socials-style5">
                              <a href="#" title="facebook">
                                <i class="fa fa fa-facebook"></i>
                              </a>
                            </div>

                            <div id="socials" class="socials-style5">
                              <a href="#" title="twitter">
                                <i class="fa fa fa-twitter"></i>
                              </a>
                            </div>

                            <div id="socials" class="socials-style5">
                              <a href="#" title="Rss">
                                <i class="fa fa fa-rss"></i>
                              </a>
                            </div>

                            <div id="socials" class="socials-style5">
                              <a href="#" title="youtube">
                                <i class="fa fa fa-youtube"></i>
                              </a>
                            </div>

                            <div id="socials" class="socials-style5">
                              <a href="#" title="google plus">
                                <i class="fa fa fa-google-plus"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="wpb_column vc_column_container vc_col-sm-8">
                    <div class="vc_column-inner">
                      <div class="wpb_wrapper">
                        <div class="wpb_text_column wpb_content_element">
                          <div class="wpb_wrapper">
                            <div class="page-title">
                              <h3>Mọi thắc mắc xin liên hệ</h3>
                            </div>
                          </div>
                        </div>

                        <div
                          role="form"
                          class="wpcf7"
                          id="wpcf7-f715-p641-o1"
                          lang="en-US"
                          dir="ltr"
                        >
                          <div class="screen-reader-response"></div>
                          <form
                            action=""
                            method="post"
                            class="wpcf7-form"
                            novalidate="novalidate"
                          >
                            <p class="border-dashed">
                              Tên Bạn :
                              <span class="wpcf7-form-control-wrap your-name">
                                <input
                                  type="text"
                                  name="your-name"
                                  value=""
                                  size="40"
                                  class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                  aria-required="true"
                                  aria-invalid="false"
                                />
                              </span>
                            </p>

                            <p class="border-dashed">
                              Email :
                              <span class="wpcf7-form-control-wrap your-email">
                                <input
                                  type="email"
                                  name="your-email"
                                  value=""
                                  size="40"
                                  class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                  aria-required="true"
                                  aria-invalid="false"
                                />
                              </span>
                            </p>

                            <p class="border-dashed">
                              Vấn đề :
                              <span
                                class="wpcf7-form-control-wrap your-subject"
                              >
                                <input
                                  type="text"
                                  name="your-subject"
                                  value=""
                                  size="40"
                                  class="wpcf7-form-control wpcf7-text"
                                  aria-invalid="false"
                                />
                              </span>
                            </p>

                            <p>
                              Nội Dung :<br />
                              <span
                                class="wpcf7-form-control-wrap your-message"
                              >
                                <textarea
                                  name="your-message"
                                  cols="40"
                                  rows="10"
                                  class="wpcf7-form-control wpcf7-textarea"
                                  aria-invalid="false"
                                ></textarea>
                              </span>
                              <br />
                              <input
                                type="submit"
                                value="Send Email"
                                class="wpcf7-form-control wpcf7-submit"
                              />
                            </p>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </LayoutCommon>
</template>

<script>
import LayoutCommon from "../Layout.vue";
export default {
  name: "ContactUs",
  components: {
    LayoutCommon,
  },
};
</script>

<style></style>
