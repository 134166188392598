<template>
  <div class="container">
    <div class="row">
      <div
        id="contents"
        role="main"
        class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
      >
        <div class="single post-6 page type-page status-publish hentry">
          <div class="entry">
            <div class="entry-content">
              <div class="entry-content">
                <SliderHeader v-if="!hiddenSlider"></SliderHeader>

                <div class="clear"></div>

                <div v-for="(category, index) in categoriesMenu" :key="index" >
                  <div class="title-main">
                    <h2 @click="gotoCategoryPage(category.id)">
                      <a>{{ category.name }}</a>
                    </h2>
                  </div>
                  <carousel v-if="products?.[category.id]?.length > 0" :dots="false" :autoplay="false" :nav="false" :items="isMobile ? 2 : 3" >
                    <div v-for="product in products[category.id]"
                      :key="product.id"
                    >
                      <ProductItem :product="product" :isMobile="isMobile"></ProductItem>
                    </div>
                  </carousel>
                  <div class="text-center" @click="gotoCategoryPage(category.id)">
                    <button class="xemthem" title="Xem tất cả">Xem tất cả</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SliderHeader from "./Header/SliderHeader.vue";
import Paging from "./Global/Paging.vue";
import ProductItem from "./ProductDetail/ProductItem.vue";
import carousel from "vue-owl-carousel";

export default {
  name: "ListProduct",
  components: {
    SliderHeader,
    Paging,
    ProductItem,
    carousel,
  },
  props: {
    categoriesMenu: {
      type: Array,
      default: () => [],
    },
    products: {
      type: Object,
      required: true,
      default: () => {},
    },
    hiddenSlider: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    this.isMobile = window.innerWidth <= 500;
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    emitPageChange({ pageIndex }) {
      this.$emit("emitPageChange", { pageIndex: pageIndex });
    },

    gotoProductDetail(id) {
      this.$router.push({ name: "ProductDetail", params: { id } });
    },
    gotoCategoryPage(categoryId) {
      this.$router.push({
        name: "ProductListCategory",
        params: { id: categoryId },
      });
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
    },
  },
};
</script>

<style scoped lang="scss">
.title-main {
  border-top: 2px solid #035cdd;
  margin-bottom: 28px;
  background: #f5f5f5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2 {
    display: inline-block;
    min-width: 250px;
    background: #035cdd;
    margin-bottom: 0;
    line-height: 38px;
    padding-left: 21px;
    padding-right: 21px;
    position: relative;
    font-family: "RobotoCondensedBold";
    color: #fff;
    font-size: 15px;
    font-weight: normal;
    text-transform: uppercase;

    &:after {
      content: "";
      width: 25px;
      height: 38px;
      position: absolute;
      right: -25px;
      bottom: 0;
      z-index: 2;
      background: url("/src/images/bg-main-tit-h2-l.png") center bottom
        no-repeat;
    }
    a {
      display: block;
      font-family: "MuliSemiBold";
      color: #fff;
      font-size: 17px;
      font-weight: normal;
      text-transform: uppercase;
    }
  }
}
.xemthem {
    margin-top: 13px;
    display: inline-block;
    padding: 7.5px 20px;
    font-size: 14px;
    color: #fff;
    background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#004d00), to(green));
}
</style>
