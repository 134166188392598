<template>
  <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 tab-col-9 description">

    <div
      class="widget-1 widget-first widget sw_related_upsell_widget-2 sw_related_upsell_widget"
      data-scroll-reveal="enter bottom move 20px wait 0.2s"
    >
      <div class="widget-inner">
        <div
          id="sw_related_upsell_widget-2"
          class="sw-woo-container-slider upsells-products responsive-slider clearfix"
          data-lg="4"
          data-md="2"
          data-sm="2"
          data-xs="1"
          data-mobile="1"
          data-speed="1000"
          data-scroll="1"
          data-interval="5000"
          data-autoplay="false"
        >
          <div class="resp-slider-container">
            <div class="block-title">
              <strong>
                <span>Sản Phẩm Giảm Giá</span>
              </strong>
              <div class="sn-img icon-bacsic2"></div>
            </div>
            <div v-show="!products.length" class="discount-message">
              <p>Chưa Có Sản Phẩm Giảm Giá</p>
            </div>

            <div class="" v-show="products?.length">
              <div
                class="item"
                v-for="(product, index) in products"
                :key="index"
              >
                <div
                  class="product-card"
                  @click.stop="gotoProductDetail(product.id)"
                >
                  <div v-if="product.salePercent" class="badge">Sale</div>
                  <div class="product-tumb">
                    <img :src="product.urlImage || imageNofoud" alt="" />
                  </div>
                  <div class="product-details">
                    <span class="product-catagory">Tên sản phẩm</span>
                    <h4>
                      <a>{{ product.name }}</a>
                    </h4>
                    <p v-html="product.description"></p>
                    <div class="product-bottom-details">
                      <div class="product-price">
                        {{ product.price | formatCurrency }} / {{ product.unit }}
                      </div>
                      <div class="product-links">
                        <a><i class="fa fa-heart"></i></a>
                        <a @click.stop="addToCartStore(product)"
                          ><i class="fa fa-shopping-cart"></i
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getWithParams } from "@/apiService.js";
// optional style for arrows & dots
import endPoint from "../../endPoint.js";
import { cartMixin } from "../mixins";
import {imageNotfound} from "../../Helper/helper.js";
export default {
  name: "UpSaleProduct",

  mixins: [cartMixin],
  data() {
    return {
      products: [],

      imageNofoud: imageNotfound
    };
  },

  mounted() {
    this.getUpSaleProduct();
  },

  methods: {
    gotoProductDetail(id) {
      if (id === this.$route.params.id) return;

      this.$router.push({ name: "ProductDetail", params: { id } });
    },
    async getUpSaleProduct() {
      const reponse = await getWithParams(endPoint.GET_UPSALE_PRODUCTS, {
        quantity: 10,
      });

      if (reponse.data) {
        this.products = reponse.data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bs-item-inner {
  cursor: pointer;
}
.discount-message {
  text-align: center;
  font-size: 20px;
  color: #333;
}

@import "@/scss/productItem.scss";

.product-card {
  display: block;
}

.product-tumb {
  height: 200px;
}
.product-price {
  margin: 0;
}
</style>
