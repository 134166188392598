<template>
  <div class="container">
    <div class="row">
      <div
        id="contents"
        role="main"
        class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
      >
        <div class="single post-6 page type-page status-publish hentry">
          <div class="entry">
            <div class="entry-content">
              <div class="entry-content">
                <SliderHeader v-if="!hiddenSlider"></SliderHeader>

                <div class="clear"></div>

                <div class="row">
                  <template v-for="product in products.items">
                    <div class="col-md-3 col-sm-6" :key="product.id">
                      <ProductItem :product="product"></ProductItem>
                    </div>
                  </template>
                </div>

                <Paging
                  v-if="products?.items?.length > 0"
                  @emitPageChange="emitPageChange"
                  :products="products"
                ></Paging>

                <div class="clear"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SliderHeader from "./Header/SliderHeader.vue";
import Paging from "./Global/Paging.vue";
import ProductItem from "./ProductDetail/ProductItem.vue";
export default {
  name: "ListProduct",
  components: {
    SliderHeader,
    Paging,
    ProductItem,
  },
  props: {
    products: {
      type: Object,
      required: true,
      default: () => {},
    },
    hiddenSlider: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  mounted() {},

  methods: {
    emitPageChange({ pageIndex }) {
      this.$emit("emitPageChange", { pageIndex: pageIndex });
    },

    gotoProductDetail(id) {
      this.$router.push({ name: "ProductDetail", params: { id } });
    },
  },
};
</script>

<style scoped>
/* Your component's CSS code goes here */
</style>
