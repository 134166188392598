<template>
  <div class="vc_row wpb_row vc_row-fluid main-slider-custom">
    <div
      class="wpb_column vc_column_container vc_col-sm-3 vc_col-lg-3 vc_col-md-4 vc_hidden-sm vc_col-xs-1 vc_hidden-xs"
    >
      <div class="vc_column-inner">
        <div class="wpb_wrapper"></div>
      </div>
    </div>

    <div
      class="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-9 vc_col-md-8 vc_col-xs-12"
    >
      <div class="vc_column-inner">
        <div class="wpb_wrapper">
          <div class="vc_row wpb_row vc_inner vc_row-fluid margin-bottom-10">
            <carousel :dots="false" :autoplay="true" :nav="false" :items="1">
              <img src="https://dienmayhaianh.vn/data/upload/logo1.jpg" />

              <img src="https://dienmayhaianh.vn/data/upload/logo1.jpg" />

              <img src="https://dienmayhaianh.vn/data/upload/logo1.jpg" />

              <img src="https://dienmayhaianh.vn/data/upload/logo1.jpg" />
            </carousel>
          </div>

          <div class="vc_row wpb_row vc_inner vc_row-fluid no-margin-bottom">
            <div
              class="home1img1 home1img wpb_column vc_column_container vc_col-sm-4"
            >
              <div class="vc_column-inner padding-right-5" style="padding-left: 0;">
                <div class="wpb_wrapper">
                  <div
                    class="wpb_single_image wpb_content_element vc_align_center"
                  >
                    <figure class="wpb_wrapper vc_figure">
                      <a
                        href="home_style_1_shop_product_default.html"
                        target="_self"
                        class="vc_single_image-wrapper vc_box_border_grey"
                      >
                        <img
                          width="301"
                          height="148"
                          class="vc_single_image-img attachment-full"
                          alt="banner-img1"
                          src="https://api.vattuhung.com/Images/dung-cu-cam-tay.jpg"
                          srcset="
                            https://api.vattuhung.com/Images/dung-cu-cam-tay.jpg 301w,
                            https://api.vattuhung.com/Images/dung-cu-cam-tay.jpg 300w
                          "
                          sizes="(max-width: 301px) 100vw, 301px"
                        />
                      </a>
                    </figure>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="home1img home1img2 wpb_column vc_column_container vc_col-sm-4 vc_hidden-xs"
            >
              <div class="vc_column-inner padding-left-5 padding-right-5">
                <div class="wpb_wrapper">
                  <div
                    class="wpb_single_image wpb_content_element vc_align_center"
                  >
                    <figure class="wpb_wrapper vc_figure">
                      <a
                        href="home_style_1_shop_product_default.html"
                        target="_self"
                        class="vc_single_image-wrapper vc_box_border_grey"
                      >
                        <img
                          width="301"
                          height="148"
                          class="vc_single_image-img attachment-full"
                          alt="banner-img2"
                          src="https://api.vattuhung.com/Images/gaslanh_.png"
                          srcset="
                            https://api.vattuhung.com/Images/gaslanh_.png 301w,
                            https://api.vattuhung.com/Images/gaslanh_.png 300w
                          "
                          sizes="(max-width: 301px) 100vw, 301px"
                        />
                      </a>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="home1img home1img2 wpb_column vc_column_container vc_col-sm-4 vc_hidden-xs"
            >
              <div class="vc_column-inner padding-left-5 padding-right-5">
                <div class="wpb_wrapper">
                  <div
                    class="wpb_single_image wpb_content_element vc_align_center"
                  >
                    <figure class="wpb_wrapper vc_figure">
                      <a
                        href="home_style_1_shop_product_default.html"
                        target="_self"
                        class="vc_single_image-wrapper vc_box_border_grey"
                      >
                        <img
                          width="301"
                          height="148"
                          class="vc_single_image-img attachment-full"
                          alt="banner-img2"
                          src="https://api.vattuhung.com/Images/dung-cu-cam-tay.jpg"
                          srcset="
                            https://api.vattuhung.com/Images/dung-cu-cam-tay.jpg 301w,
                            https://api.vattuhung.com/Images/dung-cu-cam-tay.jpg 300w
                          "
                          sizes="(max-width: 301px) 100vw, 301px"
                        />
                      </a>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="wpb_column vc_column_container vc_col-sm-3 vc_col-lg-3 vc_col-md-4 vc_hidden-sm vc_col-xs-1 vc_hidden-xs"
    >
      <div class="vc_column-inner">
        <div class="wpb_wrapper"></div>
      </div>
    </div>

    <div
      class="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-9 vc_col-md-8 vc_col-xs-12"
    ></div>
  </div>

  
</template>

<script>
import carousel from "vue-owl-carousel";
export default {
  name: "SliderHeader",
  components: { carousel },
};
</script>
