<template>
  <LayoutCommon
    :categoriesMenu="categoriesMenu"
    @get-product-by-category="getProducts"
  >
    <template v-slot:content-detail>
      <div class="container">
        <div class="row">
          <div
            id="contents"
            role="main"
            class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
          >
            <div class="post-27 page type-page status-publish hentry">
              <div class="entry-content">
                <div class="vc_row wpb_row vc_row-fluid">
                  <div class="wpb_column vc_column_container vc_col-sm-12">
                    <div class="vc_column-inner">
                      <div class="wpb_wrapper">
                        <div class="woocommerce">
                          <form method="post">
                            <table class="shop_table cart" cellspacing="0">
                              <thead>
                                <tr>
                                  <th class="product-remove">&nbsp;</th>
                                  <th class="product-thumbnail">Hình Ảnh</th>
                                  <th class="product-name">Sản Phẩm</th>
                                  <th class="product-price">Giá (VND)</th>
                                  <th class="product-quantity">Số Lượng</th>
                                  <th class="product-subtotal">Thành Tiền</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr
                                  class="cart_item"
                                  v-for="(item, index) in cartAdded"
                                  :key="index"
                                >
                                  <td class="product-remove">
                                    <a
                                      href=""
                                      class="remove"
                                      title="Remove this item"
                                      @click.prevent="deleteFromCart(item)"
                                      >&#215;</a
                                    >
                                  </td>

                                  <td class="product-thumbnail">
                                    <a>
                                      <img
                                        style="
                                          height: 100px;
                                          width: 100px;
                                          object-fit: contain;
                                        "
                                        width="180"
                                        height="180"
                                        :src="item.urlImage"
                                        class="attachment-shop_thumbnail size-shop_thumbnail wp-post-image"
                                        alt=""
                                        sizes="(max-width: 180px) 100vw, 180px"
                                      />
                                    </a>
                                  </td>

                                  <td class="product-name">
                                    <router-link
                                      :to="`/product-detail/${item.id}`"
                                    >
                                      <a>{{ item.name }}</a>
                                    </router-link>
                                  </td>

                                  <td class="product-price">
                                    <span
                                      class="woocommerce-Price-amount amount"
                                    >
                                      {{ item.price | formatCurrency }}
                                    </span>
                                  </td>

                                  <td class="product-quantity">
                                    <div class="quantity">
                                      <input
                                        type="number"
                                        step="1"
                                        min="0"
                                        max=""
                                        v-model.number="item.quantity"
                                        title="Qty"
                                        class="input-text qty text"
                                        size="4"
                                        pattern="[0-9]*"
                                        inputmode="numeric"
                                        @change="updateCart(item)"
                                      />
                                    </div>
                                  </td>

                                  <td class="product-subtotal">
                                    <span
                                      class="woocommerce-Price-amount amount"
                                    >
                                      {{
                                        (item.price * item.quantity)
                                          | formatCurrency
                                      }}
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </form>

                          <form
                            name="FormDatHang"
                            method="post"
                            action=""
                            onsubmit="return DatHang(this);"
                          >
                            <div class="cart-collaterals row">
                              <div class="info_cart col-sm-6 col-xs-12">
                                <div class="box_contact inner">
                                  <div class="title_tt">
                                    <i class="fa fa-info-circle"></i> Thông tin
                                    người nhận
                                  </div>
                                  <div class="content-box_contact">
                                    <div class="row">
                                      <div class="text-input">
                                        Họ và tên:
                                        <span style="color: red">*</span>
                                      </div>
                                      <div class="input">
                                        <input
                                          type="text"
                                          value=""
                                          required=""
                                          id="txtHoTen"
                                          name="txtHoTen"
                                          class="clsip"
                                          v-model="name"
                                        />
                                        <p
                                          v-if="!name && formSubmitted"
                                          style="color: red"
                                        >
                                          Họ tên không được để trống
                                        </p>
                                      </div>
                                      <div class="clear"></div>
                                    </div>
                                    <!---row---->
                                    <div class="row">
                                      <div class="text-input">
                                        Số điện thoại:
                                        <span style="color: red">*</span>
                                      </div>
                                      <div class="input">
                                        <input
                                          required=""
                                          value=""
                                          id="txtDienThoai"
                                          name="txtDienThoai"
                                          type="text"
                                          class="clsip"
                                          v-model="phone"
                                        />
                                        <p
                                          v-if="!phone && formSubmitted"
                                          style="color: red"
                                        >
                                          Điện thoại không được để trống
                                        </p>
                                      </div>
                                      <div class="clear"></div>
                                    </div>
                                    <!---row---->
                                    <div class="row">
                                      <div class="text-input">
                                        Địa chỉ:
                                        <span style="color: red">*</span>
                                      </div>
                                      <div class="input">
                                        <input
                                          type="text"
                                          value=""
                                          required=""
                                          name="txtDiaChi"
                                          class="clsip"
                                          v-model="address"
                                        />
                                        <p
                                          v-if="!address && formSubmitted"
                                          style="color: red"
                                        >
                                          Địa chỉ không được để trống
                                        </p>
                                      </div>
                                      <div class="clear"></div>
                                    </div>
                                    <!---row---->
                                    <div class="row">
                                      <div class="text-input">
                                        Nội dung:
                                        <span style="color: red"></span>
                                      </div>
                                      <div class="input">
                                        <textarea
                                          type="text"
                                          name="txtNoiDung"
                                          class="clsipa"
                                          v-model="description"
                                        ></textarea>
                                      </div>
                                      <div class="clear"></div>
                                    </div>
                                    <!---row---->
                                    <div class="row">
                                      <div class="text-input">
                                        Mã xác nhận:
                                        <span style="color: red">*</span>
                                      </div>
                                      <div class="input ipmaxn">
                                        <input
                                          type="text"
                                          name="txtMaXacNhan"
                                          required=""
                                          id="txtMaXacNhan"
                                          class="clsipxn"
                                          v-model="capcha"
                                        />

                                        <span class="captcha" data-val="">{{
                                          randomCaptcha
                                        }}</span>
                                      </div>

                                      <div class="clear"></div>
                                    </div>
                                    <div class="row">
                                      <div class="text-input"></div>
                                      <p
                                        class="input"
                                        v-if="
                                          capcha != randomCaptcha &&
                                          formSubmitted
                                        "
                                        style="color: red"
                                      >
                                        Mã xác nhận không đúng
                                      </p>
                                    </div>
                                    <!---row---->
                                    <div class="clear"></div>
                                  </div>
                                </div>
                              </div>
                              <div class="totals col-sm-6 col-xs-12">
                                <h3>Tổng giỏ hàng</h3>
                                <div class="inner">
                                  <table
                                    class="table shopping-cart-table-total"
                                    id="shopping-cart-totals-table"
                                  >
                                    <tfoot>
                                      <tr>
                                        <td colspan="1" class="a-left">
                                          <strong>Tổng đơn hàng</strong>
                                        </td>
                                        <td class="a-right">
                                          <strong
                                            ><span class="price">{{
                                              totalPrice | formatCurrency
                                            }}</span></strong
                                          >
                                        </td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                  <ul class="checkout">
                                    <li>
                                      <button
                                        class="btn-proceed-checkout"
                                        type="button"
                                        id="sub"
                                        @click="proceedCheckout"
                                      >
                                        <span
                                          ><i class="fa fa-ticket"></i>Đặt
                                          hàng</span
                                        >
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :is-full-page="true"
      color="#02A8F3"
    ></loading>
  </LayoutCommon>
</template>

<script>
import LayoutCommon from "../Layout.vue";
import apiService from "@/apiService.js";
import endPoint from "@/endPoint.js";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ProductListCategory",
  data() {
    return {
      categoriesMenu: [],
      isLoading: false,
      products: {},
      formSubmitted: false,
      name: "",
      description: "",
      phone: "",
      address: "",
      capcha: "",
    };
  },
  components: {
    LayoutCommon,
  },

  created() {
    this.getCategories();
  },

  computed: {
    ...mapGetters(["cartAdded", "cartTotalAmount", "cartSize"]),

    totalPrice() {
      return this.cartAdded.reduce(
        (total, item) => total + item.price * item.quantity,
        0
      );
    },

    randomCaptcha() {
      return Math.floor(Math.random() * 100000);
    },
  },
  methods: {
    ...mapActions([
      "removeFromCart",
      "addToCart",
      "deleteFromCart",
      "clearCart",
    ]),
    async getCategories() {
      const response = await apiService.get(endPoint.GET_CATEGORIES);

      this.categoriesMenu = response.data;
    },

    updateCart(item) {
      this.addToCart(item);
    },

    async proceedCheckout() {
      this.formSubmitted = true;
      if (
        !this.phone ||
        !this.name ||
        !this.address ||
        this.capcha != this.randomCaptcha
      )
        return;
      const params = this.cartAdded.map((item) => ({
        fullName: this.name,
        phoneNumber: this.phone,
        address: this.address,
        description: this.description,
        productId: item.id,
        quantity: item.quantity,
        totalAmount: item.price * item.quantity,
      }));
      try {
        this.isLoading = true;
        const response = await apiService.post(endPoint.AddListOrder, params);
        if (response.data) {
          this.$swal({
            title: "Đặt hàng thành công",
            text: "Cảm ơn bạn đã đặt hàng",
            icon: "success",
            button: "OK",
            size: "medium",
          }).then(() => {
            this.isLoading = false;
            this.clearCart();
            this.$router.push({ name: "Home" });
          });
        }
        // handle successful order...
      } catch (error) {
        this.$toast.error("Đặt hàng thất bại");
      }
    },

    getProducts(searchTerm) {
      this.$router.push({ name: "HomePage" });
    },
  },
};
</script>

<style>
@import "@/scss/cart-detail.scss";
</style>
