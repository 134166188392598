import VueRouter from 'vue-router';
import HomePageNew from './components/HomePageNew.vue';
import TheContact from './components/Global/TheContact.vue'; // Import your component
import ProductDetail from './components/ProductDetail.vue'
import ProductListCategory from  './components/ProductListCategory.vue'
import CartOrder from './components/ProductDetail/CartOrder.vue'
import ContactUs from './components/Global/ContactUs.vue'

const routes = [
    { path: '/', name: 'HomePage', component: HomePageNew},
    { path: '/home/', name: 'Home', component: HomePageNew},

    {
      path: '/Contact/',
      name: 'Contact',
      component: TheContact
    },

    {
      path: '/product-detail/:id',
      name: 'ProductDetail',
      component: ProductDetail,
     
    },
    { path: '*', redirect: '/' },
    {
      path: '/prouduct-list-category/:id',
      name: 'ProductListCategory',
      component: ProductListCategory,
    },

    {
      path:  '/cart-order/',
      name: 'CartOrder',
      component: CartOrder
    },
    {
      path:  '/contact-us/',
      name: 'ContactUs',
      component: ContactUs
    },
    {
      path: '/about/',
      name: 'AboutUs',
      component: () => import('./components/Global/AboutUs.vue')
    },
    {
      path: '/news/',
      name: 'News',
      component: () => import('./components/Global/NewsList.vue')
    },
    {
      path: '/news-detail/:id',
      name: 'NewsDetail',
      component: () => import('./components/Global/NewsDetail.vue')
    },

    {
      path: '/product-sale/',
      name: 'ProductSale',
      component: () => import('./components/ProductSale.vue')
    }


    // Add more routes here
  ];


  
  const router = new VueRouter({
    mode: 'history',
    base: process.env.VUE_APP_BASE_URL,
    routes
  });

  export default router;