<template>
  <div class="card-wrapper">
    <div class="card">
      <!-- card left -->
      <div class="product-imgs">
        <div class="img-display">
          <div class="img-showcase">
            <img
              v-if="!productDetail?.images.length"
              :src="imageNofoud"
              alt="shoe image"
            />

            <img
              v-for="image in productDetail?.images"
              :key="image.id"
              :src="image.url"
              alt="shoe image"
            />
          </div>
        </div>
        <div class="img-select">
          <div
            class="img-item"
            v-for="(image, index) in productDetail?.images"
            :key="image.id"
          >
            <a href="#" :data-id="index + 1">
              <img :src="image.url" alt="shoe image" />
            </a>
          </div>
        </div>
      </div>
      <!-- card right -->
      <div class="product-content">
        <h2 class="product-title">{{ productDetail.name }}</h2>
        <div class="product-rating">
          <i class="fas fa-star"></i>
          <i class="fas fa-star"></i>
          <i class="fas fa-star"></i>
          <i class="fas fa-star"></i>
          <i class="fas fa-star-half-alt"></i>
          <span>4.7(21)</span>
        </div>
        <div class="product-price">
          <p class="new-price">
            Thương Hiệu:
            <span>{{ productDetail.brand || "Đang cập nhật" }}</span>
          </p>
          <p class="new-price">
            Mã Sản Phẩm:
            <span>{{ productDetail.code || "N/a" }}</span>
          </p>
        </div>
        <div class="product-price">
          <p v-if="productDetail.salePercent" class="last-price">
            Giá cũ:
            <span>{{ productDetail.price * productDetail.salePercent }}</span>
          </p>
          <p class="new-price">
            Giá: <span>{{ productDetail.price | formatCurrency }}</span>
          </p>
        </div>

        <div class="product-detail">
          <span v-html="productDetail.summary"> </span>
        </div>

        <div class="purchase-info">
          <div class="input-group">
            <span class="input-group-btn">
              <button
                type="button"
                @click="decrement"
                class="btn btn-default btn-number"
                data-type="minus"
                data-field="quant[1]"
              >
                <span class="glyphicon glyphicon-minus"></span>
              </button>
            </span>
            <input
              type="text"
              name="quant[1]"
              class="form-control input-number"
              v-model="quantity"
              min="1"
            />
            <span class="input-group-btn">
              <button
                type="button"
                @click="increment"
                class="btn btn-default btn-number"
                data-type="plus"
                data-field="quant[1]"
              >
                <span class="glyphicon glyphicon-plus"></span>
              </button>
            </span>
          </div>
          <button
            type="button"
            class="btn-add"
            @click="handleAddProduct(productDetail)"
          >
            Thêm vào giỏ hàng <i class="fas fa-shopping-cart"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cartMixin } from "../mixins";
import { imageNotfound } from "../../Helper/helper.js";
export default {
  name: "ProductFancy",
  data() {
    return {
      imgId: 1,
      quantity: 1,
      imageNofoud: imageNotfound,
    };
  },
  mixins: [cartMixin],

  props: {
    productDetail: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      const imgs = document.querySelectorAll(".img-select a");
      const imgBtns = [...imgs];

      imgBtns.forEach((imgItem) => {
        imgItem.addEventListener("click", (event) => {
          event.preventDefault();
          this.imgId = imgItem.dataset.id;

          this.slideImage();
        });
      });

      window.addEventListener("resize", this.slideImage());
    });
  },

  methods: {
    slideImage() {
      const displayWidth = document.querySelector(
        ".img-showcase img:first-child"
      )?.clientWidth;

      document.querySelector(".img-showcase").style.transform = `translateX(${
        -(this.imgId - 1) * displayWidth
      }px)`;
    },
    increment() {
      this.quantity++;
    },
    decrement() {
      if (this.quantity > 1) {
        this.quantity--;
      }
    },
    handleAddProduct(productDetail) {
      productDetail.quantity = Number(this.quantity);
      productDetail.urlImage = productDetail.images[0].url;
      this.addToCartStore(productDetail);
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");

.card-wrapper {
  max-width: 1100px;
  margin: 0 auto;
  margin-bottom: 30px;
}
img {
  width: 100%;
  display: block;
}
.img-display {
  overflow: hidden;
}
.img-showcase {
  display: flex;
  width: 100%;
  max-height: 500px;
  transition: all 0.5s ease;
}
.img-showcase img {
  min-width: 100%;
}
.img-select {
  display: flex;
}
.img-item {
  margin: 0.3rem;
  max-width: 200px;
  max-height: 250px;
}
.img-item:nth-child(1),
.img-item:nth-child(2),
.img-item:nth-child(3) {
  margin-right: 0;
}
.img-item:hover {
  opacity: 0.8;
}
.product-content {
  padding: 2rem 1rem;
}
.product-title {
  font-size: 3rem;
  text-transform: capitalize;
  font-weight: 700;
  position: relative;
  color: #12263a;
  margin: 1rem 0;
}
.product-title::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  width: 80px;
  background: #12263a;
}
.product-link {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 0.9rem;
  display: inline-block;
  margin-bottom: 0.5rem;
  background: #256eff;
  color: #fff;
  padding: 0 0.3rem;
  transition: all 0.5s ease;
}
.product-link:hover {
  opacity: 0.9;
}
.product-rating {
  color: #ffc107;
}
.product-rating span {
  font-weight: 600;
  color: #252525;
}
.product-price {
  margin: 1rem 0;
  font-size: 2rem;
  font-weight: 700;
}
.product-price span {
  font-weight: 400;
}
.last-price span {
  color: #f64749;
  text-decoration: line-through;
}
.new-price span {
  color: #256eff;
}
.product-detail h2 {
  text-transform: capitalize;
  color: #12263a;
  padding-bottom: 0.6rem;
}
.product-detail p {
  font-size: 1.5rem;
  padding: 0.3rem;
  opacity: 0.8;
}
.product-detail ul {
  margin: 1rem 0;
  font-size: 0.9rem;
}
.product-detail ul li {
  margin: 0;
  list-style: none;
  background: url(https://fadzrinmadu.github.io/hosted-assets/product-detail-page-design-with-image-slider-html-css-and-javascript/checked.png)
    left center no-repeat;
  background-size: 18px;
  padding-left: 1.7rem;
  margin: 0.4rem 0;
  font-weight: 600;
  opacity: 0.9;
}
.product-detail ul li span {
  font-weight: 400;
}
.purchase-info {
  margin: 1.5rem 0;
  display: flex;

  .input-group-btn {
    vertical-align: top;
  }
}
.purchase-info .btn-add {
  border: 1.5px solid #ddd;
  text-align: center;
  padding: 6px 5px;
  outline: 0;
  margin-right: 0.2rem;
  border-radius: 5px;
  color: white;
  background: #256eff;
}
.purchase-info input {
  width: 60px;
}
.purchase-info .btn {
  cursor: pointer;
  color: #fff;
}
.purchase-info .btn:first-of-type {
  background: #256eff;
}
.purchase-info .btn:last-of-type {
  background: #f64749;
}
.purchase-info .btn:hover {
  opacity: 0.9;
}
.social-links {
  display: flex;
  align-items: center;
}
.social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: #000;
  border: 1px solid #000;
  margin: 0 0.2rem;
  border-radius: 50%;
  text-decoration: none;
  font-size: 0.8rem;
  transition: all 0.5s ease;
}
.social-links a:hover {
  background: #000;
  border-color: transparent;
  color: #fff;
}

@media screen and (min-width: 992px) {
  .card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
  }
  .card-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    float: left;
    margin-bottom: 30px;
  }
  .product-imgs {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .product-content {
    padding-top: 0;
  }
}
</style>
