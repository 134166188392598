<template>
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 tab-col-9 description">
        <span v-html="productDetail.description">

        </span>
    </div>
</template>

<script>
export default {
    name: "ProductDescription",
    props: {
        productDetail: {
            type: Object,
            required: true,
        },
    },
};

</script>