<template>
  <div class="body-wrapper theme-clearfix" id="body_wrapper">
    <a id="ya-totop" href="#" style=""></a>
    <div id="yt_header" class="yt-header wrap">
      <div class="header-style1">
        <HeaderTop />
        <!-- HEADER MENU -->
        <HeaderMenu />
        <left-menu
          :categories-menu="categoriesMenu"
          :hidden-menu-left="true"
          @search-product="getProducts"
        ></left-menu>

        <!-- HEADER CATEGORIES SEARCH -->

        <!-- END HEADER CATEGORIES SEARCH -->
      </div>
    </div>
    <div id="main" class="theme-clearfix" role="document">
      <!-- ListProduct -->
      <div class="breadcrumbs theme-clearfix" style="display: block">
        <div class="container">
          <ul class="breadcrumb">
            <li>
              <router-link to="/">Home</router-link>
              <span class="go-page"></span>
            </li>

            <li class="active">
              <span>{{ productDetail?.name }}</span>
            </li>
          </ul>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div
            id="contents-detail"
            class="content col-lg-12 col-md-12 col-sm-12 col-xs-12"
            role="main"
          >
            <div id="container">
              <div id="content" role="main">
                <div class="single-product clearfix">
                  <div itemscope="" id="product-01" class="product">
                    <product-fancy
                      v-if="productDetail"
                      :product-detail="productDetail"
                    ></product-fancy>
                  </div>
                </div>
                <div class="row">
                  <related-product
                    v-if="relatedProducts"
                    :related-products="relatedProducts"
                    :title="`Sản phẩm liên quan`"
                  ></related-product>
                  <product-description
                    v-if="productDetail"
                    :product-detail="productDetail"
                  ></product-description>

                  <related-product
                    v-if="upSaleProduct"
                    :related-products="upSaleProduct"
                    :title="`Sản phẩm Sale`"
                  ></related-product>
                  <!-- <UpSaleProduct
                        :up-sale-product="upSaleProduct"
                      ></UpSaleProduct> -->
                </div>
              </div>
            </div>
          </div>
          <!--- contents-detail --->
        </div>
      </div>
      <!-- End ListProduct -->

      <loading
        :active.sync="isLoading"
        :can-cancel="true"
        :is-full-page="true"
        color="#02A8F3"
      ></loading>
    </div>
    <Footer />
  </div>
</template>

<script>
import HeaderTop from "./Header/HeaderTop.vue";
import HeaderMenu from "./Header/HeaderMenu.vue";
import { getWithParams } from "@/apiService.js";
import apiService from "@/apiService.js";
import Loading from "vue-loading-overlay";
import endPoint from "../endPoint.js";
import Footer from "./Global/Footer.vue";
import RelatedProduct from "./ProductDetail/RelatedProduct.vue";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import LeftMenu from "./LeftMenu/LeftMenu.vue";
import UpSaleProduct from "./ProductDetail/UpSaleProduct.vue";
import { cartMixin } from "./mixins.js";
import ProductFancy from "./ProductDetail/ProductFancy.vue";
import ProductDescription from "./ProductDetail/ProductDescription.vue";
export default {
  name: "ProductDetail",

  components: {
    HeaderTop,
    HeaderMenu,
    Loading,
    LeftMenu,
    Footer,
    RelatedProduct,
    UpSaleProduct,
    ProductFancy,
    ProductDescription,
  },
  props: {},
  mixins: [cartMixin],
  data() {
    return {
      products: {},
      categoriesMenu: [],
      relatedProducts: [],
      upSaleProduct: [],
      isLoading: false,
      productDetail: null,
      imageNoTfound:
        "https://media.istockphoto.com/id/1409329028/vector/no-picture-available-placeholder-thumbnail-icon-illustration-design.jpg?s=612x612&w=0&k=20&c=_zOuJu755g2eEUioiOUdz_mHKJQJn-tDgIAhQzyeKUQ=",
    };
  },

  async created() {
    this.isLoading = true;
    await this.getProductDetail();
    await this.getUpSaleProduct();
    Promise.all([this.getCategories(), this.getRelatedProduct()])
      .then(([categories, relatedProduct]) => {
        this.isLoading = false;
      })
      .catch((error) => {
        console.error("Error loading data", error);
      });
  },
  mounted() {},

  computed: {
    productImage() {
      if (this.productDetail?.urlImages.length) {
        return this.productDetail.urlImages[0];
      }
      return this.imageNoTfound;
    },
  },

  methods: {
    async getProductDetail() {
      const response = await apiService.get(
        `${endPoint.GET_PRODUCT_DETAIL}?productId=${this.$route.params.id}`
      );
      if (response.data) {
        this.productDetail = { ...response.data, id: this.$route.params.id };
      }
    },

    async getCategories() {
      const response = await apiService.get(endPoint.GET_CATEGORIES);
      this.categoriesMenu = response.data;
    },

    async getRelatedProduct() {
      const params = {
        productId: this.$route.params.id,
        quantity: 10,
      };
      const response = await getWithParams(
        endPoint.GET_RELATED_PRODUCTS,
        params
      );
      if (response.data) {
        this.relatedProducts = response.data;
      }
    },
    async getUpSaleProduct() {
      const reponse = await getWithParams(endPoint.GET_UPSALE_PRODUCTS, {
        quantity: 10,
      });

      if (reponse.data) {
        this.upSaleProduct = reponse.data;
      }
    },

    bindingThumnailImage(images) {
      if (!images?.length) return this.imageNoTfound;
      return (
        images.find((image) => image.isThumbnail)?.url ||
        images[0]?.url ||
        this.imageNoTfound
      );
    },

    getProducts({
      searchTerm = "",
      pageIndex = 1,
      pageSize = 20,
      categoryId = null,
    } = {}) {
      this.$router.push({
        name: "ProductListCategory",
        params: { id: categoryId },
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/scss/productItem.scss";
</style>
