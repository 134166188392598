<template>
  <div class="yt-header-under-2">
    <div class="container">
      <div class="row yt-header-under-wrap">
        <div class="yt-main-menu col-md-12">
          <div class="header-under-2-wrapper">
            <div class="yt-searchbox-vermenu">
              <div class="row">
                <div class="col-lg-3 col-md-4 col-sm-3 col-xs-3 vertical-mega">
                  <!-- CATEGORIES -->
                  <div class="ver-megamenu-header">
                    <div v-if="!hiddenMenuLeft" class="mega-left-title">
                      <strong>Danh mục</strong>
                    </div>
                    <template v-else>
                      <MenuHover
                        :categories-menu="categoriesMenu"
                        @get-products-by-category="getProductsByCategory"
                      ></MenuHover>
                    </template>

                    <div class="resmenu-container">
                      <button
                        class="navbar-toggle"
                        type="button"
                        data-toggle="collapse"
                        data-target="#ResMenuleftmenu"
                      >
                        <span class="sr-only">Danh mục</span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                      </button>

                      <div
                        id="ResMenuleftmenu"
                        class="collapse menu-responsive-wrapper"
                      >
                        <ul id="menu-left-menu" class="flytheme_resmenu">
                          <li
                            class="menu-car-accessories"
                            :class="{ active: menuItem.id === activeMenuItem }"
                            v-for="(menuItem, index) in categoriesMenu"
                            :key="index"
                          >
                            <a
                              class="item-link"
                              @click="getProductsByCategory(menuItem.id)"
                            >
                              {{ menuItem.name }}</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>

                    <ul
                      :style="{
                        display:
                          isProductDetailPage || hiddenMenuLeft
                            ? 'none'
                            : 'block',
                      }"
                      id="menu-left-menu-1"
                      class="vertical-megamenu flytheme-menures"
                    >
                      <li
                        class="dropdown menu-smartphones-tablets ya-menu-custom level1"
                        :class="{ active: menuItem.id === activeMenuItem }"
                        v-for="(menuItem, index) in categoriesMenu"
                        :key="index"
                      >
                        <a
                          @click="getProductsByCategory(menuItem.id)"
                          class="item-link dropdown-toggle"
                        >
                          <span class="have-title">
                            <span class="menu-title">{{ menuItem.name }} </span>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  class="search-pro col-lg-9 col-md-8 col-sm-9 col-xs-9 no-padding-l"
                >
                  <a
                    class="phone-icon-search fa fa-search"
                    href="#"
                    title="Search"
                  ></a>

                  <div id="sm_serachbox_pro" class="sm-serachbox-pro">
                    <div class="sm-searbox-content">
                      <div class="form-search">
                        <!-- <div class="cat-wrapper">
                          <div class="selector" id="uniform-cat">
                            <label class="label-search">
                              <select name="search_category" class="s1_option">
                                <option
                                  v-for="(category, index) in categoriesMenu"
                                  :key="index"
                                >
                                  {{ category.name }}
                                </option>
                              </select>
                            </label>
                          </div>
                        </div> -->

                        <div class="input-search">
                          <input
                            type="text"
                            @keyup.enter="searchProduct"
                            v-model="searchTerm"
                            placeholder="Tìm Kiếm Sản Phẩm..."
                          />
                        </div>

                        <button
                          type="button"
                          title="Search"
                          class="fa fa-search button-search-pro form-button"
                          @click="searchProduct"
                        ></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuHover from "../Global/MenuHover.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "LeftMenu",
  components: {
    MenuHover,
  },
  props: {
    categoriesMenu: {
      type: Array,
      default: () => [],
    },

    hiddenMenuLeft: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchTerm: "",
      activeMenuItem: null,
    };
  },

  mounted() {
    if (this.getSearchTerm) {
      this.searchTerm = this.getSearchTerm;
      if (this.$route.name === "HomePage") {
        this.$emit("search-product", { searchTerm: this.searchTerm });
      }

      // check router is / or home page then get product by search term
    }
  },

  methods: {
    ...mapActions(["setSearchTerm"]),

    searchProduct() {
      this.$emit("search-product", { searchTerm: this.searchTerm });
      this.setSearchTerm(this.searchTerm);
    },

    getProductsByCategory(categoryId) {
      this.activeMenuItem = categoryId;
      if (categoryId == this.$route.params.id) return;
      // check if router is Product Category page then get product id from router params
      const isProductCategoryPage = this.$route.name === "ProductListCategory";
      if (isProductCategoryPage) {
        this.$emit("search-product", { categoryId });
        this.$router.replace({
          name: "ProductListCategory",
          params: { id: categoryId },
        });
        return;
      }
      this.$router.push({
        name: "ProductListCategory",
        params: { id: categoryId },
      });
    },
  },
  computed: {
    isProductDetailPage() {
      return this.$route.name === "ProductDetail";
    },
    ...mapGetters(["getSearchTerm", "cartAdded"]),
  },
};
</script>

<style>
/* Your CSS goes here */
.sm-serachbox-pro .form-search .input-search {
  margin-left: 0;
}
</style>
