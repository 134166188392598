import { render, staticRenderFns } from "./ProductFancy.vue?vue&type=template&id=592382f7"
import script from "./ProductFancy.vue?vue&type=script&lang=js"
export * from "./ProductFancy.vue?vue&type=script&lang=js"
import style0 from "./ProductFancy.vue?vue&type=style&index=0&id=592382f7&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports