import { mapActions } from "vuex";

// mixin.js
export const cartMixin = {
  methods: {
    ...mapActions(["addToCart"]),
    addToCartStore(product) {
        this.addToCart(product);
        this.$swal({
          title: "Success",
          text: "Thêm sản phẩm thành công !",
          icon: "success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
      },
  },
};