<template>
  <div class="body-wrapper theme-clearfix" id="body_wrapper">
    <a id="ya-totop" href="#" style=""></a>
    <div id="yt_header" class="yt-header wrap">
      <div class="header-style1">
        <HeaderTop />
        <!-- HEADER MENU -->
        <HeaderMenu> </HeaderMenu>
        <left-menu
          :hidden-menu-left="true"
          :categories-menu="categoriesMenu"
          @search-product="getProductByCategory"
        ></left-menu>
        <!-- HEADER CATEGORIES SEARCH -->

        <!-- END HEADER CATEGORIES SEARCH -->
      </div>
    </div>
    <div id="main" class="theme-clearfix" role="document">
      <!-- ListProduct -->
      <div class="breadcrumbs theme-clearfix" style="display: block">
        <div class="container">
          <ul class="breadcrumb">
            <li>
              <router-link to="/">Home</router-link>
              <!-- <a id="home" href="/">Home</a> -->
              <span class="go-page"></span>
            </li>

            <li class="active">
              <span>{{ getNameCategory }}</span>
            </li>
          </ul>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div
            id="contents-detail"
            class="content col-lg-12 col-md-12 col-sm-12 col-xs-12"
            role="main"
          >
            <div id="container">
              <div id="content" role="main">
                <!--- contents-detail --->
                <slot name="content-detail"> </slot>
                <!--- contents-detail --->
              </div>
            </div>
          </div>
          <!--- contents-detail --->
        </div>
      </div>
      <!-- End ListProduct -->

      <loading
        :active.sync="isLoading"
        :can-cancel="true"
        :is-full-page="true"
        color="#02A8F3"
      ></loading>
    </div>
    <Footer />
  </div>
</template>

<script>
import HeaderTop from "./Header/HeaderTop.vue";
import HeaderMenu from "./Header/HeaderMenu.vue";
import Loading from "vue-loading-overlay";
import Footer from "./Global/Footer.vue";
import apiService from "@/apiService.js";
import endPoint from "@/endPoint.js";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import LeftMenu from "./LeftMenu/LeftMenu.vue";
export default {
  name: "LayoutCommon",
  components: {
    HeaderTop,
    HeaderMenu,
    Loading,
    LeftMenu,
    Footer,
  },
  props: {},
  data() {
    return {
      isLoading: false,
      categoriesMenu: [],
    };
  },
  computed: {
    getNameCategory() {
      switch (this.$route.name) {
        case "AboutUs":
          return "Về Chúng Tôi";
        case "ProductListCategory":
          return this.categoriesMenu.find(
            (item) => item.id == this.$route.params.id
          )?.name;
        case "ContactUs":
          return "Liên Hệ";
        case "NewsDetail":
          return "Tin Tức Chi Tiết";
        case "News":
          return "Tin Tức";
        case "CartOrder":
          return "Giỏ Hàng";
        case "ProductSale":
          return "Sản Phẩm Khuyến Mãi";
        default:
          return "";
      }
    },
  },
  async created() {
    await this.getCategories();
  },
  mounted() {},

  methods: {
    getProductByCategory({ searchTerm, categoryId }) {
      this.$emit("get-product-by-category", { searchTerm, categoryId });
    },

    async getCategories() {
      const response = await apiService.get(endPoint.GET_CATEGORIES);

      this.categoriesMenu = response.data;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/scss/productItem.scss";
</style>
