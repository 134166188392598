<template>
  <!-- <div class="col-md-3 col-sm-6"> -->
    <div class="product-grid">
      <div :class="isMobile? 'product-image-mobile': 'product-image'">
        <a href="#">
          <img class="pic-1" :src="product?.urlImage || imageNofoud" />
        </a>
        <span v-show="product?.salePercent" class="product-discount-label"
          >{{ product?.salePercent }}%</span
        >
      </div>
      <div class="product-content">
        <h3 class="title">
          <a href="#">{{ product?.name }}</a>
        </h3>
        <div class="price">
          {{ product?.price | formatCurrency }}
          <span v-if="product?.salePercent">{{ product?.salePercent }}</span>
        </div>
      </div>
      <ul class="social">
        <li>
          <a @click="gotoProductDetail(product?.id)" data-tip="Quick View"
            ><i class="fa fa-eye"></i
          ></a>
        </li>
        <li>
          <a @click="addToCartStore(product)" data-tip="add to cart"
            ><i class="fa fa-shopping-cart"></i
          ></a>
        </li>
      </ul>
    </div>
  <!-- </div> -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { cartMixin } from "../mixins";

export default {
  mixins: [cartMixin],
  data() {
    return {
      imageNofoud:
        "https://png.pngtree.com/png-vector/20190820/ourmid/pngtree-no-image-vector-illustration-isolated-png-image_1694547.jpg",
    };
  },
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    isMobile: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapGetters(["cartSize", "cartTotalAmount", "cartAdded"]),
  },
  methods: {
    ...mapActions(["addToCart"]),
    gotoProductDetail(id) {
      this.$router.push({ name: "ProductDetail", params: { id } });
    },
  },
};
</script>

<style scoped>
.product-grid {
  font-family: "Roboto Condensed", sans-serif;
  text-align: center;
  overflow: hidden;
  position: relative;
  border: 4px solid #fff;
  margin-top: 15px;
}
.product-grid:before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  transition: all 0.3s ease 0s;
}
.product-grid:hover:before {
  background-color: rgba(0, 0, 0, 0.05);
}
.product-grid .product-image, .product-grid .product-image-mobile {
  overflow: hidden;
  position: relative;
}
.product-grid .product-image a, .product-grid .product-image-mobile a {
  display: block;
  width: 200px;
  height: 200px;
  position: relative;

  overflow: hidden;
  margin: auto;
}
.product-grid .product-image-mobile a {
  width: 137px;
}
.product-grid .product-image img {
  display: block;
  position: absolute;
  top: 40%;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  transform: translate(-50%, -50%);
}
.product-grid .product-image-mobile img {
  display: block;
  height: 200px;
  width: 137px;
  position: relative;
}
.product-discount-label {
  color: #fff;
  background-color: #000;
  font-size: 13px;
  letter-spacing: 1px;
  padding: 8px 12px;
  border-radius: 5px;
  position: absolute;
  left: 15px;
  top: 15px;
}
.product-grid .social {
  width: 100%;
  padding: 30px 0;
  margin: 0;
  list-style: none;
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
}
.product-grid .social li {
  display: inline-block;
  opacity: 0;
  transform: translateY(300%);
  transition: all 0.5s ease 0s;
}
.product-grid .social li:nth-child(2) {
  transition-delay: 0.05s;
}
.product-grid .social li:nth-child(3) {
  transition-delay: 0.13s;
}
.product-grid .social li:nth-child(4) {
  transition-delay: 0.16s;
}
.product-grid:hover .social li {
  opacity: 1;
  transform: translateY(0);
}
.product-grid .social li a {
  color: #000;
  background-color: #fff;
  line-height: 50px;
  height: 50px;
  width: 50px;
  margin: 0 2px 10px;
  border-radius: 50%;
  display: block;
  position: relative;
  z-index: 2;
  transition: all 0.3s;
}
.product-grid .social li a:hover {
  color: #fff;
  background-color: orange;
}
.product-grid .product-content {
  padding: 18px;
  position: relative;
  z-index: 2;
  background-color: white;
}
.product-grid .title {
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 10px 0;
}
.product-grid .title a {
  color: #000;
  line-height: 18px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  margin: 0 0 5px;
  height: 36px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
.product-grid .title a:hover {
  color: #fa8231;
}
.product-grid .price {
  color: #fa8231;
  font-size: 18px;
  font-weight: 600;
}
.product-grid .price span {
  color: #777;
  margin: 0 0 0 5px;
  font-weight: 300;
  text-decoration: line-through;
  display: inline-block;
}
@media only screen and (max-width: 990px) {
  .product-grid {
    margin-bottom: 30px;
  }
}
.pocial {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background: red;
  font-family: verdana;
  padding: 5px;
  border-radius: 5px;
  animation: new 0.5s linear infinite;
  margin-top: -50px;
}
.pocial a {
  text-decoration: none;
  color: #fff;
  font-weight: bold;
}
@keyframes new {
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(0.95);
  }
  100% {
    transform: scaleX(1);
  }
}
</style>
