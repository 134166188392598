<template>
  <div class="body-wrapper theme-clearfix" id="body_wrapper">
    <a id="ya-totop" href="#" style=""></a>
    <div id="yt_header" class="yt-header wrap">
      <div class="header-style1">
        <HeaderTop />
        <!-- HEADER MENU -->
        <HeaderMenu> </HeaderMenu>
        <!-- HEADER CATEGORIES SEARCH -->
        <LeftMenu
          :categories-menu="categoriesMenu"
          @search-product="getProducts"
          @get-products-by-category="getProductsByCategory"
        ></LeftMenu>
        <!-- END HEADER CATEGORIES SEARCH -->
      </div>
    </div>
    <div id="main" class="theme-clearfix" role="document">
      <div class="breadcrumbs theme-clearfix">
        <div class="container">
          <ul class="breadcrumb">
            <li class="active">
              <span>Home</span>
            </li>
          </ul>
        </div>
      </div>
      <!-- ListProduct -->
      <ListProduct
        :products="products"
        :categories-menu="categoriesMenu"
        @emitPageChange="getProducts"
      ></ListProduct>
      <!-- End ListProduct -->

      <loading
        :active.sync="isLoading"
        :can-cancel="true"
        :is-full-page="true"
        color="#02A8F3"
      ></loading>
    </div>
    <Footer />
  </div>
</template>

<script>
import HeaderTop from "./Header/HeaderTop.vue";
import HeaderMenu from "./Header/HeaderMenu.vue";
import LeftMenu from "./LeftMenu/LeftMenu.vue";
import ListProduct from "./ListProductNew.vue";
import { getWithParams } from "@/apiService.js";
import apiService from "@/apiService.js";
import Loading from "vue-loading-overlay";
import Footer from "./Global/Footer.vue";
import endPoint, { categoriesList } from "../endPoint.js";

// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "HomePageNew",
  components: {
    HeaderTop,
    HeaderMenu,
    LeftMenu,
    ListProduct,
    Loading,
    Footer,
  },
  props: {},
  data() {
    return {
      products: {},
      categoriesMenu: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getCategories();
    // this.getProducts();
  },
  methods: {
    async getProducts(categoryId) {
      const params = {
        pageIndex: 1,
        pageSize: 6,
        categoryId,
      }
      const response = await getWithParams("/api/client/SearchProductAsync", params);
      this.products = {...this.products, [categoryId]: response.data.items}
      this.isLoading = false;
    },

    async getCategories() {
      this.categoriesMenu = categoriesList;
      await categoriesList.forEach(category => {
        this.getProducts(category.id)
      })
    },

    async getProductsByCategory(categoryId) {
      const response = await apiService.get(
        `${endPoint.GET_PRODUCT_BY_CATEGORY}?categoryId=${categoryId}`
      );
      this.products = {...this.products, [categoryId]: response.data.items}
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.products-loop li {
  max-height: 390px;
}
</style>
