// create vue component with script and style tags

<template>
  <div class="yt-header-middle">
    <div class="container">
      <div class="row">
        <!-- LOGO -->
        <div class="col-lg-3 col-md-2 col-sm-12 col-xs-12 logo-wrapper">
          <router-link to="/">
            <a href="/">
              <img
                class="logo-style"
                src="../../images/logo-removebg-preview.png"
                alt="sw shoppy"
                width="140"
                height="57"
              /> </a
          ></router-link>
        </div>
        <!-- END LOGO -->

        <div class="col-lg-9 col-md-10 col-sm-12 col-xs-12 yt-megamenu">
          <div class="yt-header-under">
            <nav id="primary-menu" class="primary-menu">
              <div class="yt-menu">
                <div class="navbar-inner navbar-inverse">
                  <div class="resmenu-container">
                    <!-- LIST MOBILE MENU ITEMS -->
                    <button
                      class="navbar-toggle"
                      type="button"
                      data-toggle="collapse"
                      data-target="#ResMenuprimary_menu"
                    >
                      <span class="sr-only">Categories</span>
                      <span class="icon-bar"></span>
                      <span class="icon-bar"></span>
                      <span class="icon-bar"></span>
                    </button>

                    <div
                      id="ResMenuprimary_menu"
                      class="collapse menu-responsive-wrapper"
                    >
                      <ul id="menu-primary-menu" class="flytheme_resmenu">
                        <li class="active res-dropdown menu-blog">
                            <a @click="goToHome" class="item-link dropdown-toggle">Trang Chủ</a>
  
                          <span class="show-dropdown"></span>   
                        </li>

                        <li class="style3 res-dropdown menu-daily-deals">
                          <a
                            @click="goToProductSale"
                            class="item-link dropdown-toggle"
                            >Sản phẩm giảm giá</a
                          >
                          <span class="show-dropdown"></span>
                        </li>
                        <li class="res-dropdown menu-blog">
                          <a
                            @click="goToContactUs"
                            class="item-link dropdown-toggle"
                            href="#"
                            >Liên Hệ</a
                          >
                          <span class="show-dropdown"></span>
                        </li>

                        <li class="res-dropdown menu-portfolios">
                          <a
                            @click="goToAboutUs"
                            class="item-link dropdown-toggle"
                            >Về chúng tôi</a
                          >
                          <span class="show-dropdown"></span>
                        </li>

                        <li class="res-dropdown menu-portfolios">
                          <a @click="goToNews" class="item-link dropdown-toggle"
                            >Tin Tức</a
                          >
                          <span class="show-dropdown"></span>
                        </li>
                      </ul>
                    </div>
                    <!-- END LIST MOBILE MENU ITEMS -->
                  </div>

                  <!-- LIST NORMAL MENU ITEMS -->
                  <ul
                    id="menu-primary-menu-1"
                    class="nav nav-pills nav-mega flytheme-menures"
                  >
                    <li
                      class="active dropdown menu-portfolios ya-menu-custom level1"
                    >
                      <a @click="goToHome" class="item-link dropdown-toggle">
                        <span class="have-title">
                          <span class="menu-title">Trang Chủ</span>
                        </span>
                      </a>
                    </li>

                    <li
                      class="style3 dropdown menu-daily-deals ya-mega-menu level1"
                    >
                      <a
                        @click="goToProductSale"
                        class="item-link dropdown-toggle"
                      >
                        <span class="have-title">
                          <span class="menu-title">Sản phẩm giảm giá</span>
                        </span>
                      </a>
                     
                    </li>

                    <li class="dropdown menu-portfolios ya-menu-custom level1">
                      <a
                        @click="goToContactUs"
                        class="item-link dropdown-toggle"
                      >
                        <span class="have-title">
                          <span class="menu-title">Liên hệ</span>
                        </span>
                      </a>
                    </li>

                    <li class="dropdown menu-portfolios ya-menu-custom level1">
                      <a @click="goToAboutUs" class="item-link dropdown-toggle">
                        <span class="have-title">
                          <span class="menu-title">Về chúng tôi</span>
                        </span>
                      </a>
                    </li>

                    <li class="dropdown menu-portfolios ya-menu-custom level1">
                      <a @click="goToNews" class="item-link dropdown-toggle">
                        <span class="have-title">
                          <span class="menu-title">Tin Tức</span>
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>

            <!-- SHOPPING CART -->
            <div class="mini-cart-header">
              <div
                class="top-form top-form-minicart minicart-product-style pull-right"
              >
                <div class="top-minicart pull-right">
                  <a
                    class="cart-contents"
                    title="View your shopping cart"
                    @click="goToCartPage"
                  >
                    <span class="minicart-number">{{ cartInfo.cartSize }}</span>
                    item -
                    <span class="woocommerce-Price-amount amount">
                      {{ cartInfo.cartTotalAmount | formatCurrency }}
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <!-- END SHOPPING CART -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "HeaderMenu",
  props: {},
  mounted() {},

  methods: {
    
    goToHome() {
      if (this.$router.currentRoute.name !== "Home") {
        this.$router.push({ name: "Home" });
      }
    },

    goToProductSale() {
      if (this.$router.currentRoute.name !== "ProductSale") {
        this.$router.push({ name: "ProductSale" });
      }
    },

    goToCartPage() {
      if (!this.cartSize) {
        return;
      }
      if (this.$router.currentRoute.name !== "CartOrder") {
        this.$router.push({ name: "CartOrder" });
      }
    },

    goToContactUs() {
      if (this.$router.currentRoute.name !== "ContactUs") {
        this.$router.push({ name: "ContactUs" });
      }
    },

    goToAboutUs() {
      if (this.$router.currentRoute.name !== "AboutUs") {
        this.$router.push({ name: "AboutUs" });
      }
    },

    goToProductList() {
      if (this.$router.currentRoute.name !== "ProductListCategory") {
        this.$router.push({ name: "ProductListCategory" });
      }
    },

    goToNews() {
      if (this.$router.currentRoute.name !== "News") {
        this.$router.push({ name: "News" });
      }
    },
  },

  computed: {
    ...mapGetters(["cartTotalAmount", "cartSize"]),

    cartInfo() {
      return {
        cartTotalAmount: this.cartTotalAmount,
        cartSize: this.cartSize,
      };
    },
  },
};
</script>

<style scoped>
.logo-style {
  width: 100px;
  height: 80px;
  object-fit: cover;
}
</style>
