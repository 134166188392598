<template>
  <nav id="mainnav" class="mainnav">
    <ul class="menu">
      <li class="home">
        <a>Danh Muc</a>
        <ul class="submenu">
          <li
            class="menu-car-accessories"
            :class="{ active: menuItem.id === activeMenuItem }"
            v-for="(menuItem, index) in categoriesMenu"
            :key="index"
          >
            <a class="item-link" @click="getProductsByCategory(menuItem.id)">
              {{ menuItem.name }}</a
            >
          </li>
          <!-- <li><a href="index.html">Home 01</a></li>
          <li><a href="index-v2.html">Home 02</a></li>
          <li><a href="index-v3.html">Home 03</a></li>
          <li><a href="index-v4.html">Home 04</a></li>
          <li><a href="index-v5.html">Home 05</a></li>
          <li><a href="index-v6.html">Home 06</a></li>
          <li><a href="index-v7.html">Home 07</a></li> -->
        </ul>
        <!-- /.submenu -->
      </li>
    </ul>
    <!-- /.menu -->
  </nav>
</template>

<script>
export default {
  name: "MenuHover",

  props: {
    categoriesMenu: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      menuHover: false,
      activeMenuItem: null,
    };
  },
  methods: {
    showMenu() {
      this.menuHover = true;
    },
    hideMenu() {
      this.menuHover = false;
    },

    getProductsByCategory(id) {
      this.activeMenuItem = id;
      this.$emit("get-products-by-category", id);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/_colors.scss";
nav {
  display: block;
  height: 45px;
  background-color: #444444;
}
#mainnav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#mainnav > ul > li {
  display: inline-block;
  padding: 0 6px;
}
#mainnav ul li {
  position: relative;
}
.header #mainnav > ul > li > a {
  line-height: 100px;
}
#mainnav > ul > li.home > a {
  color: #fff;

  font-size: 125%;
  font-weight: bold;
  line-height: 52px;
  text-transform: uppercase;

  &:before {
    width: 52px;
    height: 52px;
    content: "";
    display: inline-block;
    background-image: url("/src/css/assets/img/group_btn.png");
    background-repeat: no-repeat;
    background-position: center -238px;
    left: -40px;
    top: 0px;
    position: absolute;
  }
}
#mainnav > ul > li > a {
  position: relative;
  right: -20px;
  display: block;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  color: #222;
  text-decoration: none;
  font-size: 14px;
  margin: 0 15px;
  font-weight: 600;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#mainnav ul.submenu {
  position: absolute;
  left: 0;
  top: 150%;
  width: 300px;
  text-align: left;
  background-color: #fff;
  z-index: 9999;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#mainnav ul.submenu li:first-child {
  border-top: none;
}
#mainnav ul.submenu > li {
  border-top: 1px solid #ebebeb;
}
#mainnav ul li ul li {
  margin-left: 0;
}
#mainnav ul.submenu > li > a {
  display: block;
  color: #333333;
  text-decoration: none;
  padding: 0 0 0 20px;
  line-height: 45px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #02a8f3;
  }
}

#mainnav ul li:hover > ul.submenu {
  top: 100%;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  opacity: 1;
  visibility: visible;
  color: #333333;
}
</style>
