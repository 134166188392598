<template>
  <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 tab-col-3">
    <div
      id="relate-01"
      class="carousel slide sw-related-product"
      data-ride="carousel"
      data-interval="0"
    >
    <div class="block-title">
              <strong>
                <span>{{title}}</span>
              </strong>
              <div class="sn-img icon-bacsic2"></div>
            </div>

      <div class="carousel-inner">
        <div
          class="item active"
          v-for="(product, index) in relatedProducts"
          :key="index"
        >
          <div class="bs-item cf">
            <div class="bs-item-inner">
              <div class="item-img">
                <a @click="gotoProductDetail(product.id)" :title="product.name">
                  <img
                    width="180"
                    height="180"
                    class="attachment-shop_thumbnail size-shop_thumbnail wp-post-image"
                    :alt="product.title"
                    :src="product.urlImage || imageNofoud"
                  />
                </a>
              </div>

              <div class="item-content">
                <div class="star"><span style="width: 65px"></span></div>

                <h4>
                  <a
                    @click="gotoProductDetail(product.id)"
                    :title="product.title"
                    >{{ product.name }}</a
                  >
                </h4>

                <p>
                  <span class="woocommerce-Price-amount amount">
                    {{ product.price | formatCurrency }}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {imageNotfound} from "../../Helper/helper.js";
export default {
  name: "RelatedProduct",
  props: {
    relatedProducts: {
      type: Array,
      default: () => [],
    },

    title: {
      type: String,
      default: "Sản Phẩm Giảm Giá",
    },
  },

  data() {
    return {
      imageNofoud:
      imageNotfound
    };
  },

  mounted() {
  },
  methods: {
    gotoProductDetail(id) {
      if (id == this.$route.params.id) return;
      this.$router.push({ name: "ProductDetail", params: { id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.bs-item-inner {
  cursor: pointer;
}
</style>
