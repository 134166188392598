export const endPoint = {
    GET_CATEGORIES: '/api/client/GetCategoriesAsync',
    SEARCH_PRODUCTS: '/api/client/SearchProductsAsync',
    GET_PRODUCT_BY_CATEGORY: '/api/client/GetProductByCategory',
    GET_PRODUCT_DETAIL: '/api/Client/GetProductDetail',
    GET_RELATED_PRODUCTS: '/api/Client/GetRelatedProduct',
    GET_UPSALE_PRODUCTS: '/api/Client/GetProductSale',
    AddListOrder: '/api/Client/AddListOrder',
}

export const categoriesList = [
    {
        "id": 1,
        "code": "001",
        "name": "VẬT TƯ MÁY GIẶT"
    },
    {
        "id": 2,
        "code": "002",
        "name": "VẬT TƯ TỦ LẠNH"
    },
    {
        "id": 3,
        "code": "003",
        "name": "VẬT TƯ MÁY LẠNH"
    },
    {
        "id": 4,
        "code": "004",
        "name": "VẬT TƯ MÁY LỌC NƯỚC"
    },
    {
        "id": 5,
        "code": "005",
        "name": "VẬT TƯ PHỤ"
    },
    {
        "id": 6,
        "code": "006",
        "name": "ĐỒ NGHỀ THỢ"
    }
];

export default endPoint;